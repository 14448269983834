import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import TestimonialSlider from '../components/TestimonialSlider';
import { projects } from '../data/data';

const Work = () => {
    return (
        <section id='work' className='page work'>
            <div className='work-main'>
                <div className='projects'>
                    <h2 className='section-title'>WHAT AM I UP TO?</h2>
                    <p className='sh'>
                        <span className='accent'>I</span> recently stepped away from corporate work to <span className='accent'>explore the wide world of freelancing</span>. Here are a couple of my most recent projects.
                    </p>
                    <div className='projects-list'>
                        {projects.map((item, index) => {
                            return (
                                <div className='project' key={index}>
                                    <div 
                                        className='project-bg-image' 
                                        style={{ 
                                            backgroundImage: `url(${item.image})`
                                        }}></div>
                                    <div className='project-content'>
                                        <p>{item.month} {item.year}</p>
                                        <h3>{item.name}</h3>
                                        <a
                                            href={item.url}
                                            target='blank' 
                                            rel='nofollow noreferrer noopener' 
                                            className='project-link'
                                        >
                                            <button className='project-button'>
                                                CHECK IT OUT
                                                <FontAwesomeIcon 
                                                    icon={faArrowUpRightFromSquare} 
                                                    size='sm' 
                                                    className='project-button-icon' 
                                                />
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <TestimonialSlider />
            </div>
        </section>
    );
};

export default Work;


import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { testimonials } from '../data/data';

const TestimonialSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 5000);
    
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="testimonial-slider">
            <h3 className='testimonial-title'>What do my clients say?</h3>
            <div className='testimonial-content'>
                <div 
                    className="testimonial-slides"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {testimonials.map((item, index) => (
                        <blockquote key={index} className="testimonial-slide">
                            <p>{item.text}</p>
                            <figcaption>
                                — {item.author},
                                <a
                                    href={item.link}
                                    target="_blank" 
                                    rel="nofollow noreferrer noopener"
                                    className="testimonial-link"
                                >
                                    <em>{item.company}</em>
                                </a>
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="testimonial-icon" />
                            </figcaption>
                        </blockquote>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TestimonialSlider;